<div
  class="feature-item container list"
  [ngClass]="{ featureSelected: selected && selected.id === feature.id }"
>
  <div
    class="img w-100"
    matRipple
    [matRippleColor]="'#16161614'"
    (click)="goToFeature(feature.id)"
  >
    <img
      (load)="onImageLoad()"
      loading="lazy"
      class="w-100"
      [src]="feature.Files | img : '2' : 1"
      [alt]="feature.Product?.Name_Product"
    />
  </div>
  <div class="w-100 content-left">
    <h2 class="info-text-primary">{{ feature.Product.Name_Product }}</h2>
    <p class="info-text-secondary">{{ feature.Product.Description_Product }}</p>
    <p matTooltip="{{ feature.Details | details }}" class="info-text-secondary">
      {{ feature.Details | details }}
    </p>

    <h2
      class="price-format"
      [ngClass]="{ priceAdded: feature.isAdded }"
      *ngIf="!feature?.ProductPrice?.Discount"
    >
      {{ feature.ProductPrice | finalPrice | async }}
    </h2>
    <p *ngIf="feature?.ProductPrice?.Discount">
      <b class="discount">
        {{ feature.ProductPrice | finalPrice : null : null : false | async }}
      </b>
      <b class="f-25 price-format discount-price">
        {{ feature.ProductPrice | finalPrice : null : null : true | async }}
      </b>
    </p>
  </div>
  <div class="add-section">
    <button
      aria-label="Remove"
      (click)="reduceToCart(feature, $event)"
      [disabled]="awaitAdd"
      class="remove"
      *ngIf="feature.isAdded"
    >
      <mat-icon *ngIf="feature.isAdded > 1">remove</mat-icon>
      <mat-icon *ngIf="feature.isAdded === 1">delete_outline</mat-icon>
    </button>

    <h1 *ngIf="feature.isAdded" class="uni-add-section">
      {{ feature.isAdded }}
    </h1>
    <button
      (click)="addToCart(feature, $event)"
      [disabled]="awaitAdd"
      *ngIf="!feature.isAdded"
      class="add-to-car"
    >
      <mat-icon>add</mat-icon>
    </button>
    <button
      (click)="addToCart(feature, $event)"
      [disabled]="awaitAdd"
      *ngIf="feature.isAdded"
      class="add"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
