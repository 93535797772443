[
  {
    "id": "643",
    "src": "assets/icons/categories/personalCare.svg"
  },
  {
    "id": "645",
    "src": "assets/icons/categories/grain.svg"
  },
  {
    "id": "649",
    "src": "assets/icons/categories/masas.svg"
  },
  {
    "id": "642",
    "src": "assets/icons/categories/cereals.svg"
  },
  {
    "id": "647",
    "src": "assets/icons/categories/liqueur.svg"
  },
  {
    "id": "640",
    "src": "assets/icons/categories/canned.svg"
  },
  {
    "id": "641",
    "src": "assets/icons/categories/coffee.svg"
  },
  {
    "id": "655",
    "src": "assets/icons/categories/snacks.svg"
  },
  {
    "id": "652",
    "src": "assets/icons/categories/soda.svg"
  },
  {
    "id": "639",
    "src": "assets/icons/categories/Cooking oil.svg"
  },
  {
    "id": "648",
    "src": "assets/icons/categories/home.svg"
  },
  {
    "id": "646",
    "src": "assets/icons/categories/dairy.svg"
  },
  {
    "id": "644",
    "src": "assets/icons/categories/sweeteners.svg"
  },
  {
    "id": "653",
    "src": "assets/icons/categories/Salt and Pepper.svg"
  },
  {
    "id": "650",
    "src": "assets/icons/categories/pastas.svg"
  },
  {
    "id": "654",
    "src": "assets/icons/categories/aderezos.svg"
  },
  {
    "id": "656",
    "src": "assets/icons/categories/sopas.svg"
  },
  {
    "id": "651",
    "src": "assets/icons/categories/baby.svg"
  },
  {
    "id": "657",
    "src": "assets/icons/categories/salud.svg"
  },
  {
    "id": "658",
    "src": "assets/icons/categories/tecnology.svg"
  },
  {
    "id": "659",
    "src": "assets/icons/categories/mascotas.svg"
  },
  {
    "id": "660",
    "src": "assets/icons/categories/insecticidas.svg"
  },
  {
    "id": "661",
    "src": "assets/icons/categories/automotriz-icon.svg"
  },
  {
    "id": "662",
    "src": "assets/icons/categories/cigarros.svg"
  },
  {
    "id": "663",
    "src": "assets/icons/categories/postres.svg"
  },
  {
    "id": "664",
    "src": "assets/icons/categories/oficina.svg"
  },
  {
    "id": "849",
    "src": "assets/icons/categories/carnes.svg"
  },
  {
    "id": "850",
    "src": "assets/icons/categories/geriatric.svg"
  },
  {
    "id": "851",
    "src": "assets/icons/categories/oficina.svg"
  },
  {
    "id": "853",
    "src": "assets/icons/categories/pan.svg"
  },
  {
    "id": "882",
    "src": "assets/icons/categories/Frutas.svg"
  },
  {
    "id": "883",
    "src": "assets/icons/categories/Verduras.svg"
  }
]
