/* src/app/components/search/search.component.less */
.search-wrapper {
  display: flex;
  align-items: center;
}
.input-container {
  position: relative;
  flex: 1;
}
.search-input {
  width: 100%;
  padding-right: 50px !important;
  height: 26px;
  max-width: 500px;
  min-width: 290px;
  padding: 5px;
  float: left;
  font-size: 15px;
  font-family: "Poppins-Regular";
  color: #7e7e7e;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #e1e1e1;
  border: none;
}
@media screen and (max-width: 960px) {
  .search-input {
    min-width: unset;
  }
}
.btn-search {
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translate(100%, -50%);
  cursor: pointer;
  color: #7e7e7e;
}
.search-wrapper input:focus {
  outline: 0;
}
.search-wrapper input::-webkit-input-placeholder {
  color: #999;
  font-weight: normal;
  font-style: italic;
}
.search-wrapper input:-moz-placeholder {
  color: #999;
  font-weight: normal;
  font-style: italic;
}
.search-wrapper input:-ms-input-placeholder {
  color: #999;
  font-weight: normal;
  font-style: italic;
  border-style: none;
}
.search-wrapper button {
  overflow: visible;
  position: relative;
  float: left;
  border: 0;
  padding: 0;
  cursor: pointer;
  height: 35px;
  width: 200px;
  font-size: 15px;
  color: #000000;
  font-family: "Poppins-Regular";
  background: #e1e1e1;
  border-radius: 5px 0 0 5px;
  border: none;
}
.search-wrapper button span {
  width: 100%;
  display: flex;
  height: 26px;
  border-right: 1px solid #6c6c6c;
  position: relative;
  z-index: 10;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
}
.search-wrapper button:active,
.search-wrapper button:focus {
  outline: 0;
}
.delete-option {
  cursor: pointer;
}
.delete-option:hover {
  color: #54bc00;
}
.search-wrapper .search-input:focus + .no-click {
  display: none;
}
.no-click {
  opacity: 1;
}
#searchInputFull::focus-within button {
  opacity: 0;
}
@media screen and (max-width: 800px) {
  .category-button {
    display: none;
  }
  .search-input {
    border-radius: 5px;
    padding-right: 0px !important;
  }
  .btn-search {
    right: 0px;
    transform: translate(0%, -50%);
  }
}
/*# sourceMappingURL=search.component-224JTIWT.css.map */
